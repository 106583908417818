import { Button, Modal, Table } from 'react-bootstrap';
import { useState, useEffect } from 'react';

interface ModalProps {
  show: boolean;
  setShow: (showFlag: boolean) => void;
  itemList: any[];
  nextStep: (item: any) => void;
}

const SelectModal: React.FC<ModalProps> = ({ show, setShow, itemList, nextStep }) => {
  const [selectItem, setSelectItem] = useState<any>({});
  const [selectIndex, setSelectIndex] = useState<number>(-1);

  const selectListItem = (item: any, index: number) => {
    setSelectIndex(index);
    setSelectItem(item);
  };

  useEffect(() => {
    if (!show) return;
    setSelectIndex(-1);
    setSelectItem({});
  }, [show]);

  return (
    <Modal
      size="lg"
      show={show}
      onHide={() => {
        setShow(false);
      }}
      scrollable={true}
    >
      <Modal.Header closeButton>
        <Modal.Title>映像作品を選択</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <>
          以下から選択してください。
          <Table bordered>
            <thead>
              <tr>
                <th>商品タイトル</th>
                <th>トラックタイトル</th>
                <th>品番</th>
                <th>映像種別</th>
                <th>発売日</th>
              </tr>
            </thead>
            <tbody>
              {itemList.map((item, index) => (
                <tr
                  key={index}
                  onClick={() => {
                    selectListItem(item, index);
                  }}
                  className={selectIndex === index ? 'alert-primary' : ''}
                >
                  <td>{item.syohinTitle}</td>
                  <td>{item.trackTitle}</td>
                  <td>{item.hinban}</td>
                  <td>{item.videoType}</td>
                  <td>{item.hatsubaibi}</td>
                </tr>
              ))}
            </tbody>
          </Table>
          {itemList.length === 0 && <div>一致する検索結果がありません。</div>}
        </>
      </Modal.Body>
      <Modal.Footer>
        <Button
          variant="secondary"
          onClick={() => {
            setShow(false);
          }}
        >
          閉じる
        </Button>
        <Button
          variant="danger"
          onClick={() => {
            nextStep(selectItem);
          }}
          disabled={!Object.keys(selectItem).length}
        >
          選択
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default SelectModal;
