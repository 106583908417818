import { WidgetProps } from '@rjsf/utils';
import { Button, Form, Col, Alert } from 'react-bootstrap';
import { useState, useEffect } from 'react';
import api from '../api/CallListApi';
import { useDispatch } from 'react-redux';
import { startLoading, stopLoading } from '../../store/loadingReducer';
import Modal from './modal/SelectMovieModal';
import Axios from 'axios';
import { useErrorBoundary } from 'react-error-boundary';

function MovieWidget(props: WidgetProps) {
  const [keyword1, setKeyword1] = useState<string>('');
  const [keyword1Suffix, setKeyword1Suffix] = useState<string>('を含む');
  const [keyword2, setKeyword2] = useState<string>('');
  const [keyword2Suffix, setKeyword2Suffix] = useState<string>('');
  const [searchStrategy, setSearchStrategy] = useState<'A' | 'AandB' | 'AnotB'>('A');
  const [showModal, setShowModal] = useState<boolean>(false);
  const [itemList, setItemList] = useState<any[]>([]);
  const [showAlert, setShowAlert] = useState<boolean>(false);
  const [errorMsg, setErrorMsg] = useState<string>('');
  const { showBoundary } = useErrorBoundary();
  const dispatch = useDispatch();

  const getOperator = (): 'and' | 'not' | undefined => {
    switch (searchStrategy) {
      case 'A':
        return undefined;
      case 'AandB':
        return 'and';
      case 'AnotB':
        return 'not';
      default:
        throw new Error('検索方式が不正です。');
    }
  };

  const search = async () => {
    try {
      dispatch(startLoading());
      const operator = getOperator();
      const data = await api.getMovie(keyword1, operator ? keyword2 : undefined, operator);
      setErrorMsg('');
      setShowAlert(false);
      setItemList(data);
      setShowModal(true);
    } catch (e: any) {
      if (!Axios.isAxiosError(e)) showBoundary(e);
      if (e.response.status === 400) {
        setErrorMsg(e.response.data);
        setShowAlert(true);
      } else showBoundary(e);
    } finally {
      dispatch(stopLoading());
    }
  };

  const setSelectedItem = (item: any) => {
    setShowModal(false);
    props.formContext.updateMovie(item);
  };

  useEffect(() => {
    switch (searchStrategy) {
      case 'A':
        setKeyword1Suffix('を含む');
        setKeyword2Suffix('');
        break;
      case 'AandB':
        setKeyword1Suffix('を含み');
        setKeyword2Suffix('を含む');
        break;
      case 'AnotB':
        setKeyword1Suffix('を含み');
        setKeyword2Suffix('を含まない');
        break;
      default:
        return;
    }
  }, [searchStrategy]);

  return (
    <div>
      <Form.Row>
        <Col md={12}>
          <Alert variant="danger" show={showAlert}>
            {errorMsg}
          </Alert>
        </Col>
        <Col md={12}>
          <Form.Group>
            <Form.Check
              id="A"
              value="A"
              type="radio"
              name="searchStrategy"
              label="キーワード1を含むデータを検索"
              onChange={(e: any) => setSearchStrategy(e.target.value)}
              defaultChecked={true}
            />
            <Form.Check
              id="AandB"
              value="AandB"
              type="radio"
              name="searchStrategy"
              label="キーワード1とキーワード2の両方を含むデータを検索"
              onChange={(e: any) => setSearchStrategy(e.target.value)}
            />
            <Form.Check
              id="AnotB"
              value="AnotB"
              type="radio"
              name="searchStrategy"
              label="キーワード1を含み、キーワード2を含まないデータを検索"
              onChange={(e: any) => setSearchStrategy(e.target.value)}
            />
          </Form.Group>
        </Col>
        <Col md={12} lg={4}>
          <label htmlFor="keyword1">キーワード1</label>
          <Form.Control
            type="text"
            id="keyword1"
            onChange={(event) => {
              setKeyword1(event.target.value);
            }}
          />
        </Col>
        <Col md={12} lg={1} style={{ marginTop: '40px' }}>
          <span style={{ fontSize: 'small' }}>{keyword1Suffix}</span>
        </Col>
        <Col md={12} lg={4}>
          <label htmlFor="keyword2">キーワード2</label>
          <Form.Control
            type="text"
            id="keyword2"
            onChange={(event) => {
              setKeyword2(event.target.value);
            }}
            disabled={searchStrategy === 'A'}
          />
        </Col>
        <Col md={12} lg={1} style={{ marginTop: '40px' }}>
          <span style={{ fontSize: 'small' }}>{keyword2Suffix}</span>
        </Col>
        <Col md="auto">
          <Button
            variant="primary"
            type="button"
            as="input"
            value="検索"
            className="m-10"
            style={{ marginTop: '30px' }}
            onClick={() => {
              search();
            }}
          />
        </Col>
      </Form.Row>
      <Modal show={showModal} setShow={setShowModal} itemList={itemList} nextStep={setSelectedItem} />
    </div>
  );
}

export default MovieWidget;
