import { UiSchema } from '@rjsf/utils';
import CustomFieldTemplate from './components/CustomFieldTemplate';
import CustomBooleanFieldTemplate from './components/CustomBooleanFieldTemplate';
import CustomSearchFieldTemplate from './components/CustomSearchFieldTemplate';
import IsbnInputWidget from './components/InputIsbnCode';
import InputAuthorizedTextbookWidget from './components/InputAuthorizedTextbook';
import InputNHKForSchoolWidget from './components/InputNHKForSchool';
import InputMovieWidget from './components/InputMovie';
import InputImageWidget from './components/InputImage';
import InputBroadcasterNameWidget from './components/InputBroadcasterName';
import { FrontWorkTmp } from './interface/FrontWorkTmp';
import { FrontWork } from './interface/FrontWork';
import { MediaCategory } from './interface/MediaCategory';

export const DANCE_TITLE = '(ダンス等)既存の振り付け';

export const THEATER_TITLE = '(演劇等)既存の台本';

export const toastConfig = (): any => {
  return {
    position: 'top-center',
    closeOnClick: true,
    pauseOnHover: true,
    progress: undefined,
    theme: 'light',
  };
};

export const toastNotAutoCloseConfig = (): any => {
  return {
    position: 'top-center',
    closeOnClick: true,
    pauseOnHover: true,
    progress: undefined,
    theme: 'light',
    autoClose: false,
    className: 'big-toast',
  };
};

export function createTitleDict(jsonschema: any, schemaKey: string = 'dummy', titleDict: object = {}) {
  if (Array.isArray(jsonschema)) {
    for (const key of jsonschema) createTitleDict(jsonschema[key], key, titleDict);
  }
  if (typeof jsonschema === 'object') {
    for (const key of Object.keys(jsonschema)) {
      if (key === 'title' && typeof jsonschema[key] === 'string') titleDict[schemaKey] = jsonschema[key];
      createTitleDict(jsonschema[key], key, titleDict);
    }
  }
  return titleDict;
}

const getProperties = (jsonschema, result = {}) => {
  if (Array.isArray(jsonschema)) {
    for (const key of jsonschema) getProperties(jsonschema[key], result);
  }
  if (typeof jsonschema === 'object') {
    for (const key of Object.keys(jsonschema)) {
      if (['properties', '$defs'].includes(key) && typeof jsonschema[key] === 'object') {
        const properties = jsonschema[key];
        for (const property of Object.keys(properties)) {
          if (properties[property].title) result[property] = properties[property];
        }
      }
      getProperties(jsonschema[key], result);
    }
  }
  return result;
};

export function createUiSchema(jsonschema: any) {
  const uiSchema: UiSchema = {};
  const properties: object = getProperties(jsonschema);
  for (const property in properties) {
    const val: UiSchema = {};
    val['ui:title'] = properties[property].title;
    switch (property) {
      case 'isbn':
        val['ui:widget'] = IsbnInputWidget;
        val['ui:FieldTemplate'] = CustomFieldTemplate;
        break;
      case 'input_authorized_textbook_assist':
        val['ui:widget'] = InputAuthorizedTextbookWidget;
        val['ui:FieldTemplate'] = CustomSearchFieldTemplate;
        break;
      case 'input_nhk_for_school_assist':
        val['ui:widget'] = InputNHKForSchoolWidget;
        val['ui:FieldTemplate'] = CustomSearchFieldTemplate;
        break;
      case 'input_movie_assist':
        val['ui:widget'] = InputMovieWidget;
        val['ui:FieldTemplate'] = CustomSearchFieldTemplate;
        break;
      case 'image':
        val['ui:widget'] = InputImageWidget;
        val['ui:FieldTemplate'] = CustomFieldTemplate;
        break;
      case 'broadcaster':
        val['ui:widget'] = InputBroadcasterNameWidget;
        val['ui:FieldTemplate'] = CustomFieldTemplate;
        break;
      default:
        if (properties[property].type === 'boolean') {
          val['ui:FieldTemplate'] = CustomBooleanFieldTemplate;
          break;
        }
        val['ui:FieldTemplate'] = CustomFieldTemplate;
        if (properties[property].readOnly) val['ui:placeholder'] = 'システムから自動入力される項目です。';
    }
    uiSchema[property] = val;
  }
  return uiSchema;
}

export function getNextUrl(path: string) {
  // pathから次の遷移urlを絶対パスで返却する
  const itemArray = path.split('/');
  const now = itemArray[3];
  if (now === 'lesson') {
    itemArray[3] = 'bigMediaCategory';
  } else if (now === 'bigMediaCategory') {
    itemArray[3] = 'smallMediaCategory';
  } else if (now === 'smallMediaCategory') {
    itemArray[3] = 'media';
  } else if (now === 'media') {
    itemArray[3] = 'work';
  } else if (now === 'work') {
    if (itemArray.length === 4) {
      itemArray[3] = 'work/confirm';
    } else {
      // work/confirmの場合
      return '/' + itemArray[1] + '/reports';
    }
  }
  return itemArray.join('/');
}

export function getMediaUrl(path: string) {
  const itemArray = path.split('/');
  itemArray[itemArray.length - 1] = 'media';
  return itemArray.join('/');
}

export function returnWorkPage(path: string) {
  const itemArray = path.split('/');
  return '/' + itemArray[1] + '/regist/work';
}

export function moveReportsPage(path: string) {
  const itemArray = path.split('/');
  return '/' + itemArray[1] + '/reports';
}

export function getErrorUrl(path: string) {
  const pathArray = path.split('/');
  return '/' + pathArray[1] + '/error';
}

export const getLessonSchema = (): any => {
  return {
    title: '授業選択',
    type: 'object',
    required: ['subject', 'grade', 'personCount'],
    properties: {
      subject: {
        title: '教科、授業科目等名',
        type: 'string',
      },
      grade: {
        title: '学年',
        type: 'array',
        minItems: 1,
        items: {
          type: 'number',
          enum: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12],
        },
        uniqueItems: true,
      },
      personCount: {
        title: '履修者等の人数',
        description: '※同じ著作物を複数の授業で送信された場合には、複数授業の延べ人数でご入力いただけます。',
        type: 'integer',
        minimum: 1,
      },
    },
  };
};

export const getLessonUiSchema = (): any => {
  return {
    subject: {
      'ui:FieldTemplate': CustomFieldTemplate,
      'ui:title': '教科、授業科目等名',
    },
    grade: {
      'ui:widget': 'checkboxes',
      'ui:options': { inline: true },
      'ui:FieldTemplate': CustomFieldTemplate,
      'ui:title': '学年',
    },
    personCount: {
      'ui:FieldTemplate': CustomFieldTemplate,
      'ui:title': '履修者等の人数',
    },
  };
};

export function getLoginUrl(path: string) {
  const itemArray = path.split('/');
  if (itemArray[1] === 'users') {
    if (itemArray[2] !== 'select') return '/' + itemArray[1] + '/select';
  }
  return '/' + itemArray[1] + '/login';
}

export const getInputListByPrevData = (works: FrontWorkTmp[]): FrontWork[] => {
  let inputItems: FrontWork[] = [];
  for (let i = 0; i < works.length; i++) {
    inputItems.push({ data: works[i], disabled: false });
  }
  return inputItems;
};

export const getErrorMessage = (error: any, title: string) => {
  let stack = '';
  let message = '';
  switch (error.name) {
    case 'pattern':
      if (title === 'ISBNコード') {
        message = 'ハイフン無しの10桁もしくは13桁でご入力ください。';
        stack = '[' + title + ']' + message;
        break;
      }
      message = '有効な' + title + 'をご入力ください。';
      stack = '[' + title + ']' + message;
      break;
    case 'required':
      message = '必須入力です。';
      stack = '[' + title + ']' + message;
      break;
    case 'minItems':
      message = String(error.params.limit) + '個以上選択してください。';
      stack = '[' + title + ']' + message;
      break;
    case 'maxLength':
      message = String(error.params.limit) + '文字以内でご入力ください。';
      stack = '[' + title + ']' + message;
      break;
    case 'type':
      message = error.params.type === 'integer' ? '整数でご入力ください。' : error.params.type + 'でご入力ください。';
      stack = '[' + title + ']' + message;
      break;
    case 'minimum':
      message = String(error.params.limit) + '以上の値をご入力ください。';
      stack = '[' + title + ']' + message;
      break;
    case 'format':
      message = '正しいフォーマットでご入力ください。';
      stack = '[' + title + ']' + message;
      break;
    default:
      message = error.message;
      stack = error.stack;
      break;
  }
  return { message: message, stack: stack };
};

export const getStringValue = (value: any) => {
  if (value === 'undefined') return '';
  if (value === true) return 'チェックあり';
  if (value === false) return 'チェックなし';
  return String(value);
};

export const setSmallMediaCategorySelected = (data: MediaCategory[], selectedMediaCategory: string) => {
  if (selectedMediaCategory) {
    for (const item of data) {
      const index = selectedMediaCategory.indexOf(item.value.join(','));
      if (index !== -1) {
        item.isSelected = true;
      } else {
        item.isSelected = false;
      }
      if (item.children.length !== 0) {
        setSmallMediaCategorySelected(item.children, selectedMediaCategory);
      }
    }
  }
  return data;
};
